import React, { useEffect, useState } from "react";
import { auth, provider } from "../../Config/Config";
import { signInWithPopup } from "firebase/auth";
import { useAuth } from "../../AuthProvider/AuthProvider";

function SignInWithGoogle() {
    const [email, setEmail] = useState('');
    const { setIsLoggedIn } = useAuth();

    const handleClick = () => {
        signInWithPopup(auth, provider)
            .then((data) => {
                console.log("data", data);
                const userEmail = data.user.email;
                const userName = data.user.displayName;
                const userImg = data.user.photoURL;
                
                setEmail(userEmail);
                localStorage.setItem("userName", userName);
                localStorage.setItem("userImg", userImg);
                localStorage.setItem("email", userEmail);
                window.location = "/";
            })
            .catch((error) => {
                console.error("Error signing in:", error);
                window.location= "/register";
            })    
    }

    useEffect(() => {
        setEmail(localStorage.getItem('email'))

    })

    return (
        <div>
            <button
                className="bg-blue-800 px-5 py-3 rounded-md" 
                onClick={handleClick}> Signin With Google</button>
        </div>
    );
}
export default SignInWithGoogle;
