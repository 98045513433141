import React, { useState, useEffect } from 'react'
import { images } from '../../Constants';
import axios from 'axios';

export const MyProfile = () => {

    const userEmail = localStorage.getItem("email");
    const userName = localStorage.getItem("userName");
    const [isProfile, setIstProfile] = useState(false);
    const [profileData, setProfileData] = useState(null);
// rkJbr4Nnr8Nq9oXt beyondscool
    
    const [formData, setFormData] = useState({
        name: userName,
        mobileNumber: '',
        fathersMobileNumber: '',
        email: userEmail,
        city: '',
        school: '',
        class: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission here
        try {
            await axios.post('https://beyond-scool.vercel.app/api/profiles', formData)
                .then(response => {
                    console.log('Data saved successfully');
                })
                .catch(error => {
                    console.error('Error saving data:', error);
                })
                .finally(() => {
                    setFormData({
                        name: '',
                        mobileNumber: '',
                        fathersMobileNumber: '',
                        email: '',
                        city: '',
                        school: '',
                        class: ''
                    });
                    window.location = "/myprofile";

                });

        } catch (error) {
            console.error('Error saving data:', error);
        }
        
    };


    // fetching data
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                if (!userEmail) {
                    console.log('Email is required');
                    return;
                }

                axios.get(`https://beyond-scool.vercel.app/api/getProfile?email=${userEmail}`)
                    .then(response => {
                        if (response.status === 200) {
                            // console.log("success profile fetched")
                            setProfileData(response.data);
                            setIstProfile(true);
                        } else if (response.status === 404) {
                            console.error('Profile not found');
                            setProfileData(null);
                            setIstProfile(false);
                        } else {
                            console.error('Unexpected status code:', response.status);
                            setProfileData(null);
                            setIstProfile(false);
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching profile:', error);
                        setProfileData(null);
                        setIstProfile(false);
                    });
            } catch (error) {
                setProfileData(null);
                setIstProfile(false);

            }
        };

        fetchProfile();
    }, [userEmail]);

    return (
        <div className=''>



            {/* right side section */}
            <div className='pt-4 pb-5 flex flex-col justify-center '>
                <div className='text-2xl font-medium text-left pl-2'>
                    MyProfile
                </div>
                <form onSubmit={handleSubmit} className=" w-96 mt-8 px-6 text-left mx-auto">
                    <label className="block text-xs font-light uppercase mb-1">Name</label>
                    <input
                        type="text"
                        name="name"
                        placeholder={userName}
                        disabled
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-black rounded-md mb-8"
                    />

                    <label className="block text-xs font-light uppercase mb-1">Mobile Number</label>
                    <input
                        type="text"
                        name="mobileNumber"
                        placeholder={ profileData?.mobileNumber ? profileData.mobileNumber  : ""}
                        disabled={ profileData}
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-black rounded-md mb-8"
                        required
                    />

                    <label className="block text-xs font-light uppercase mb-1">Father's Mobile Number</label>
                    <input
                        type="text"
                        name="fathersMobileNumber"
                        placeholder={ profileData?.fathersMobileNumber ? profileData.fathersMobileNumber  : ""}
                        disabled={ profileData}
                        value={formData.fathersMobileNumber}
                        onChange={handleChange}
                        className="w-full h-9 px-4 py-2  border border-black rounded-md mb-8"
                        required
                    />

                    <label className="block text-xs font-light uppercase mb-1">Email</label>
                    <input
                        type="email"
                        name="email"
                        placeholder={userName}
                        disabled
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full h-9 px-4 py-2 border border-black rounded-md mb-8"
                    />

                    <label className="block text-xs font-light uppercase mb-1">City</label>
                    <input
                        type="text"
                        name="city"
                        placeholder={ profileData?.city ? profileData.city  : ""}
                        disabled={ profileData}
                        value={formData.city}
                        onChange={handleChange}
                        className="w-full h-9 px-4 py-2 border border-black rounded-md mb-8"
                        required
                    />

                    <label className="block text-xs font-light uppercase mb-1">School</label>
                    <input
                        type="text"
                        name="school"
                        placeholder={ profileData?.school ? profileData.school  : ""}
                        disabled={ profileData}
                        value={formData.school}
                        onChange={handleChange}
                        className="w-full h-9 px-4 py-2 border border-black rounded-md mb-8"
                        required
                    />

                    <label className="block text-xs font-light uppercase mb-1">Class</label>
                    <input
                        type="text"
                        name="class"
                        placeholder={ profileData?.class ? profileData.class  : ""}
                        disabled={ profileData}
                        value={formData.class}
                        onChange={handleChange}
                        className="w-full h-9 px-4 py-2 border border-black rounded-md mb-8"
                        required
                    />

                    {!isProfile &&
                        <button
                            type="submit"
                            className="bg-[#5960FF] hover:bg-[#4851fb] float-right text-[14px] text-white uppercase font-bold py-2 px-4 rounded-md"
                        >
                            Save
                        </button>
                    }
                </form>

{/*                 <div className=' flex gap-4 bg-[#5960FF] w-full px-3 py-5 mt-24 text-left text-white rounded-t-lg md:hidden'>
                    <div className=' w-12 h-12 pt-2'>
                        <img src={images.Vector} alt="vector" />
                    </div>
                    <div>
                        <p className='text-[36px] font-bold'>Help & Support</p>
                        <p className='text-[16px] font-normal'> Call us at <a href="tel:+91 9876543210" >+91 9876543210 </a></p>
                        <p>or</p>
                        <p className='text-[16px] font-normal'> Email at <a href="mailto:support@beyondscool.com" >support@beyondscool.com</a></p>
                    </div>
                </div> */}
            </div>

        </div>
    )
}
