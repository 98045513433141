import { useEffect } from 'react';
import './App.css';
import { Navbar } from './Components/Navbar/Navbar';
import { Routes, Route, useLocation } from "react-router-dom";
import { Home } from './Components/Home/Home';
import { Register } from './Components/Register/Register';
import { MyProfile } from './Components/MyProfile/MyProfile';
import { Learning } from './Components/Learning/Learning';
import UnauthorizedAccess from './Components/UnauthorizedAccess/UnauthorizedAccess';
import { useAuth } from './AuthProvider/AuthProvider';
import Privacy from "./Components/Pages/Privacy";
import Refund from "./Components/Pages/Refund";
import Terms from "./Components/Pages/Terms";
import { Footer } from './Components/Footer/Footer';
import { About } from './Components/Pages/About';
import { Contact } from './Components/Contact/Contact';
import '@fortawesome/fontawesome-free/css/all.css';

// import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


function App() {
  const { isLoggedIn } = useAuth();
  const { setIsLoggedIn } = useAuth();

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      setIsLoggedIn(true);
    }
    // console.log("isloggedIn", isLoggedIn);
  });

  const location = useLocation();

  return (
    <div className="App font-inter">

      {location.pathname !== "/register" && <Navbar />}

      <Routes>
        <Route path="/" element={<Home />} />
        {!isLoggedIn ?
          (
            <>
              <Route path="register" element={<Register />} />
              <Route path="*" element={<UnauthorizedAccess />} />
            </>

          )
          : (
            <>
              <Route path="myprofile" element={<MyProfile />} />
            </>
          )
        }

        <Route path="courses/*" element={<Learning />} />
        <Route path="privacypolicy" element={<Privacy />} />
        <Route path="terms&conditions" element={<Terms />} />
        <Route path="refundpolicy" element={<Refund />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />

      </Routes>
      <a href="https://wa.me/+919972148403" class="whatsapp_float" target="_blank" rel="noopener noreferrer">
        <div className='wamid'>
          <i class="fa-brands fa-whatsapp "></i>
        </div>
      </a>

      {location.pathname !== "/register" && <Footer />}

    </div>
  );
}

export default App;
