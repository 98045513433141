// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "@firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// copy firbase config
const firebaseConfig = {
    apiKey: "AIzaSyCYQ9uGiYUgcFoTpJoqT5FkdMiAluCFspk",
    authDomain: "beyondscool-f6571.firebaseapp.com",
    projectId: "beyondscool-f6571",
    storageBucket: "beyondscool-f6571.appspot.com",
    messagingSenderId: "634772417668",
    appId: "1:634772417668:web:f72d941788a98ce53ee6d1",
    measurementId: "G-P42ZRSX2KQ"
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
export {auth,provider};
