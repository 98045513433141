import React from 'react'

const Refund = () => {
    return (
        <div
            className='group-33 p-4  sm:p-16  text-left font-inter'
        // style={{ backgroundImage: `url(${images.blurbackground}) `, backgroundSize: 'cover', backgroundColor: 'rgb(89 96 255 / 40%)' }}
        >
            <h2 className=' text-3xl sm:text-4xl font-extrabold'>REFUND Policy</h2>
            <p className='text-xs sm:text-xl font-medium text-justify pt-5 sm:pt-7'> All educational content available on the Beyondscool platform can be conveniently purchased through our
                user-friendly website. Whenever you're accessing our services via website, you can seamlessly order
                course materials and various additional products or services. The following provisions within this clause
                are applicable to your course-related purchases. Upon placing an order for course materials through the
                website, we will promptly send a confirmation email to acknowledge your request. This confirmation serves
                as information only and does not constitute acceptance of your offer. The binding contract between us will
                be established when you receive an invoice for applicable charges or a dispatch confirmation of your order,
                whichever occurs first. We reserve the right to decline orders due to factors such as insufficient stock,
                obvious errors, or inaccuracies in the goods or prices displayed on the platform.
                For ease of access, all course-related content, including video lectures, will be made available to you via
                the app or sent to your registered email within 48 working hours of placing the order. Clear instructions for
                accessing and downloading the content will be provided in the email. Payments for course materials can
                be conveniently made through the app or website using credit/debit cards. In the event of non-acceptance
                of your order, a refund will be promptly processed. If a credit card transaction is declined or not approved,
                access to the course material will be granted upon receipt of full payment.
                Goods ordered will be digitally delivered to the registered user, streamlining the learning process. Please
                note that once course-related goods and services are accessed, they are non-returnable. While we strive
                for accuracy and reliability in providing course information, we do not provide legal representations or
                warranties. We are not liable for any loss or damage resulting from inaccurate, out-of-date, or incomplete
                course content.
                Should you encounter any inaccuracies or suspect intellectual property infringements, please contact
                us immediately at beyondscool@gmail.com The course material on the platform is provided without
                guarantees, conditions, or warranties as to its accuracy. We and third parties connected to us expressly
                exclude implied conditions, warranties, and terms, to the extent permitted by law.
                We are not liable for any inability to access the platform or services, and we do not guarantee
                uninterrupted operation, continuous availability, or a constant format or structure. We are not responsible
                for errors, corruption, or security breaches during the transmission of Information or material over any
                telecommunications network. Our liability to you shall not exceed the amounts paid at the time of your
                claim against us.
                Courses purchased are completely non refundable. These conditions do not affect your statutory rights as
                a consumer, if applicable.--
                Governing Law: The agreement shall be governed by and construed in accordance with the laws of
                the Republic of India.
                Jurisdiction: Any legal action or proceedings arising out of or in connection with this agreement shall
                be submitted to the exclusive jurisdiction of the courts in Delhi, India.
                Venue: The parties agree that the appropriate venue for any legal action or proceedings shall be the
                competent courts located in Delhi, India..​​</p>

        </div>
    )
}

export default Refund