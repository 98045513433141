// import axios from "axios";

// export default async function displayRazorpay(amount, className, subject, isLoggedIn) {

//     const email = localStorage.getItem("email")

//     if (email) {

//         try {
//             const response = await axios.post("https://beyond-scool.vercel.app/razorpay", {
//                 amount
//             });

//             const { data } = response;

//             console.log("data", data);


//             const options = {
//                 key: "rzp_live_7e4829EGar0omp",
//                 currency: "INR",
//                 amount: amount,
//                 name: "Beyondscool",
//                 description: "Wallet Transaction",
//                 //   image: "http://localhost:1337/logo.png",
//                 order_id: data.id,
//                 handler: function (response) {
//                     // alert("PAYMENT ID ::" + response.razorpay_payment_id);
//                     // alert("ORDER ID :: " + response.razorpay_order_id);
//                     alert("check your registered mail span for confirmation mail")

//                     // Make a POST request to the backend using Axios
//                     axios.post("https://beyond-scool.vercel.app/onsuccessfullpayement", {
//                         email: email,  
//                         className: className,
//                         subject: subject,
//                     })
//                         .then((response) => {
//                             console.log("Response from backend:", response.data);
//                         })
//                         .catch((error) => {
//                             console.error("Error posting data to backend:", error);
//                         });
//                 },
//                 prefill: {
//                     name: "",
//                     email: "",
//                     contact: "",
//                     className: className,
//                     subjecct: subject
//                 },
//                 notes: {
//                     className: className,
//                     subject: subject
//                 }
//             };

//             const paymentObject = new window.Razorpay(options);
//             paymentObject.open();
//         } catch (error) {
//             console.error("Error creating Razorpay order:", error);
//         }
//     }

//     else {
//         window.location = "/register";
//     }
// }

import axios from "axios";

export default async function displayRazorpay(amount, className, subject, isLoggedIn) {
    const email = localStorage.getItem("email");

    if (email) {
        try {
            axios.get(`https://beyond-scool.vercel.app/api/getProfile?email=${email}`)
                .then(response => {
                    if (response.status === 200) {
                        // const profileData = response.data;
                        // console.log("Profile data:", profileData);

                        // Razorpay payment flow
                        axios.post("https://beyond-scool.vercel.app/razorpay", {
                            amount
                        })
                            .then(response => {
                                const { data } = response;
                                // console.log("Razorpay data:", data);

                                const options = {
                                    key: "rzp_live_7e4829EGar0omp",
                                    currency: "INR",
                                    amount: amount,
                                    name: "Beyondscool",
                                    description: "Wallet Transaction",
                                    order_id: data.id,
                                    handler: function (response) {
                                        alert("Check your registered mail span for confirmation mail");

                                        // Make a POST request to the backend using Axios
                                        axios.post("https://beyond-scool.vercel.app/onsuccessfullpayement", {
                                            email: email,
                                            className: className,
                                            subject: subject,
                                        })
                                            .then((response) => {
                                                // console.log("Response from backend:", response.data);
                                            })
                                            .catch((error) => {
                                                console.error("Error posting data to backend:", error);
                                            });
                                    },
                                    prefill: {
                                        name: "",
                                        email: "",
                                        contact: "",
                                        className: className,
                                        subjecct: subject
                                    },
                                    notes: {
                                        className: className,
                                        subject: subject
                                    }
                                };

                                const paymentObject = new window.Razorpay(options);
                                paymentObject.open();
                            })
                            .catch(error => {
                                console.error("Error creating Razorpay order:", error);
                            });
                    } else if (response.status === 404) {
                        // console.error('Profile not found');
                        window.location = "/myprofile";
                    } else {
                        console.error('Unexpected status code:', response.status);
                        window.location = "/myprofile";
                    }
                })
                .catch(error => {
                    console.error('Error fetching profile:', error);

                    alert("Before processing for payment, complete your Beyondscool profile.");
                    window.location = "/myprofile";
                    
                });
        } catch (error) {
            console.error("Error fetching user profile:", error);
            // window.location = "/register";
        }
    } else {
        window.location = "/register";
    }
}

