import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import { images } from '../../../Constants';


const faq = [
    {
        topic: "What subjects do you offer tutoring in?",
        description: "As of today , we are offering Maths , Science , Physics and Language ​"
    },
    {
        topic: "What grade levels do you cater to?​",
        description: "elementary, middle school, high school, college, etc.  ​"
    },
    {
        topic: "What is your teaching approach/methodology?​",
        description: "All our course and Batches are designed to clear Basic concept and solve complex questions .​"
    },
    {
        topic: "Do you offer personalized lesson plans?​",
        description: "Our different range of courses are designed by personalized lesson plans  .​"
    },
    {
        topic: "What are the scheduling options?​",
        description: "For Live classes – Students are allotted to a batch according to their favourable and flexible timings.Courses – Anyone can avail any course any time and able to schedule doubt class after interacting with their mentor."
    },
    {
        topic: "How are tutoring sessions conducted?​",
        description: "The Classes are online mode with interacting setup. Don’t worry we provide free demo class .​"
    },
    {
        topic: "How can I get in touch with customer support?​​",
        description: "You can contact through live chat or ​ Email – beyondscool@gmail.com​ Phone - 9972148403 ​.​"
    },
    {
        topic: "Do you offer free trial sessions?​",
        description: "We offer 3 free demo classes.​​​"
    },

]


export const FAQ = () => {

    const [showDescription, setShowDescription] = useState(new Array(faq.length).fill(false));

    const toggleDescription = (index) => {
        const newShowDescription = [...showDescription];
        newShowDescription[index] = !newShowDescription[index];
        setShowDescription(newShowDescription);
    };

    return (
        <div className='py-14 px-4 sm:p-16'>
            <div className='flex items-center justify-between '>
                <h2 className=' text-2xl sm:text-7xl font-bold text-left uppercase'>Frequently<br className='sm:hidden'></br> Asked Questions</h2>
                <div className='w-28 h-28 sm:w-80 sm:h-80 sm:flex items-center'>
                    <img src={images.ques} alt="FAQ"
                        className='w-full h-full'
                    />
                </div>
            </div>

            <ul>
                <hr className='border-[2px] border-[#D7DEF0]'></hr>
                {faq.map((data, index) => (
                    <div>
                        <li className='text-xl text-left sm:text-2xl font-extrabold py-4 sm:py-6 grid gap-6'>
                            <div className='flex justify-between items-center'>
                                <p className='cursor-pointer' onClick={() => toggleDescription(index)}>{data.topic}</p>
                                <i className={`fa-solid fa-plus opacity-75 cursor-pointer ${showDescription[index] ? 'rotate-45' : ''}`} onClick={() => toggleDescription(index)}></i>
                            </div>
                            {showDescription[index] && <div className='text-xl font-normal text-justify bg-darkgray w-full p-5 rounded-lg'>{data.description}</div>}
                        </li>
                        <hr className='border-[2px] border-[#D7DEF0]'></hr>
                    </div>
                ))}
            </ul>
        </div>
    )
}


