import React, { useState, useEffect } from 'react';
import { images } from '../../Constants';
import '@fortawesome/fontawesome-free/css/all.css';
import SignInWithGoogle from "../SignIn/SignInWithGoogle";

export const Register = () => {

    return (


        <div className='bg-[#FECB01] px-6 py-7 md:px-14 md:py-11 h-[100vh] '>
            <div className='bg-white flex md:justify-between justify-center  rounded-[40px] h-[94vh] md:h-[90vh] '>


                <div className='form w-[70vh] h-[90vh] flex flex-col justify-center items-center pt-24 px-5 md:px-14 md:py-11'>
                    <div className='heading felx flex-col text-left pb-12'>
                        <p className='text-[16px] md:text-[32px] font-normal uppercase'>WELCOME TO</p>
                        <div className='flex flex-row gap-4'>
                            <div className=' w-12 h-12 md:w-20 md:h-20 rounded'>
                                <img src={images.logo} alt="logo" />
                            </div>
                            <h2 className='text-2xl md:text-5xl flex items-center font-semibold'>Beyond Scool</h2>
                        </div>
                    </div>

                    <div className=' flex justify-center items-center md:text-xl font-light text-white underline md:pt-8 w-[vw] '>
                        {/* <div className=' md:text-xl font-light text-[#1400FF] underline md:pt-8'>Switch to Login?</div>  */}
                        <SignInWithGoogle />
                    </div>
                </div>


                <div className=' hidden lg:block bg-blue-500 w-[35vw] h-[90vh] rounded-[40px]'>
                </div>

                <div className=' hidden lg:block  absolute bottom-10  right-44  lg:w-[450px] lg:h-[400px] xl:w-[778px] xl:h-[703px]'>
                    <img src={images.CharacterObject} alt="characterObject" />
                </div>
            </div>

        </div >
    )
}


