import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { images } from '../../../Constants';
import { useAuth } from '../../../AuthProvider/AuthProvider';

const Menus = [
    { name:"Home", path:"/"},
    { name: "About", path: "/about" },
    { name: "Courses", path: "/courses" },
    
    { name: "Contact Us", path: "/contact" }
]


const dropDown = [
    {
        name: "My Profile",
        path: "myprofile"
    },
    // {
    //     name: "My Courses",
    //     path: "mycourses"
    // },
]

export const SlideIn = ({toggle, setToggle}) => {

    const {isLoggedIn} =useAuth();
    const userName = localStorage.getItem("userName");

    const handleToggle = (toggle) => {
        setToggle(!toggle);
    }

    return (
        <div className='flex flex-col bg-white h-[100vh] relative w-full'>

            <div className='p-5 bg-[#EFEFEF] '>
                {!isLoggedIn ? (
                    <div className='flex justify-center'>
                        <div className="bg-[#FFCC00] text-black rounded-3xl px-4 border-4 border-[#eac73a] shadow-lg flex items-center">
                            <NavLink
                                className={`flex gap-3 py-2 px-2 `}
                                to="/register"
                                activeClassName="text-blue"
                                onClick={() => handleToggle(toggle)}
                            >
                                <span className={` text-2xl font-semibold `}>
                                    Login/Sign up
                                </span>
                            </NavLink>
                        </div>
                    </div>
                ) : (
                    <div className=' text-2xl text-left'>
                        <h2 className=' font-extralight pb-6'>Hey {userName ? userName : "User"}</h2>
                        <ul className=' flex flex-col gap-4'>
                            {dropDown.map((data, index) => (
                                <li className='pl-2 '>
                                    <NavLink
                                        to={data.path}
                                        className="flex justify-start items-center gap-4"
                                        onClick={() => handleToggle(toggle)}
                                    >
                                        <div className='w-[14px] h-[9px] flex items-center'>
                                            <img src={images.DropDown} alt="dropdown"></img>
                                        </div>
                                        <div className=' font-semibold'>{data.name}</div>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className={` pt-6   ${isLoggedIn ? "px-14" : "px-7"}`}>
                <ul className='flex gap-2 flex-col'>
                    {Menus?.map((menu, i) => (
                        <li className="">
                            <NavLink
                                className={`flex gap-3 py-2 px-2 `}
                                to={menu.path}
                                onClick={() => handleToggle(toggle)}
                            >
                                <span className={` text-2xl font-semibold  `}>
                                    {menu.name}
                                </span>
                            </NavLink>
                        </li>
                    ))}
                </ul>


            </div>

            <div className=' bg-[#5960FF] w-full fixed bottom-0 px-10 py-4  text-left text-white rounded-t-lg md:hidden'>
                <h2 className='text-3xl font-bold'>Still Confused?</h2>
                <p className='text-[16px] font-normal'>Our expert can answer all your questions.</p>

                <div className="bg-[#FFCC00] text-black rounded-3xl px-4 my-2 border-4 shadow-lg flex items-center justify-center whitespace-nowrap">
                  <a href="tel:+91 9876543210 " className="flex w-full items-center justify-center">
                        <div className=' w-8 h-8'>
                            <img src={images.getCall} alt="getcall" />
                        </div>

                        <span className={` text-2xl sm:text-sm font-semibold `}>
                            Get a Call from us
                        </span>
{/*                     </NavLink> */}
                      </a>
                </div>

                <p className='text-[16px] font-normal'> or Call us at  <a href="tel:+91 9876543210" >+91 9876543210 </a></p>
            </div>
        </div>
    )
}
