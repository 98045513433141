import React from 'react'

const Privacy = () => {
    return (
        <div
            className='group-33 p-4  sm:p-16  text-left font-inter'
        // style={{ backgroundImage: `url(${images.blurbackground}) `, backgroundSize: 'cover', backgroundColor: 'rgb(89 96 255 / 40%)' }}
        >
            <h2 className=' text-3xl sm:text-4xl font-extrabold'>Privacy Policy</h2>
            <h3 className='text-xs sm:text-xl font-medium text-justify pt-5 sm:pt-7'>Privacy Policy for Next Toppers App</h3>
            <p>At Beyondscool, we respect your privacy and are committed to protecting your personal information. This
                privacy policy explains how we collect, use, and disclose your personal information when you use our
                website.</p>
            <h3 className='text-xs sm:text-xl font-medium text-justify pt-5 sm:pt-7'>Information We Collect</h3>
            When you use our website, we may collect certain information about you, including:--
            Personal information: We may collect your name, email address, phone number, and other
            information you provide to us when you register for an account or use certain features of the App.
            Usage information: We may collect information about how you use the website, such as the pages
            you view, the features you use, and the actions you take.
            Device information: We may collect information about the device you use to access the website,
            such as your device type, operating system version, and mobile network information.
            <h3 className='text-xs sm:text-xl font-medium text-justify pt-5 sm:pt-7'>HowWeUseYourInformation</h3>
            We may use your personal information to:--
            Provide and improve the App: We may use your information to operate and maintain the website,
            customize your experience, and improve our services.
            Communicate with you: We may use your information to send you promotional messages, updates
            about the App, and other communications.
            Respond to your requests: We may use your information to respond to your inquiries and fulfill your
            requests.
            <h3 className='text-xs sm:text-xl font-medium text-justify pt-5 sm:pt-7'> HowWeShare Your Information </h3>
            Wemayshare your personal information with third parties in the following circumstances:--
            Service providers: We may share your information with third-party service providers who help us
            operate the website and provide our services.
            Business partners: We may share your information with business partners who help us promote the
            website and our services.
            Legal purposes: We may share your information if we believe it is necessary to comply with
            applicable laws or regulations, respond to a subpoena or court order, or protect our rights or
            property.
            <h3 className='text-xs sm:text-xl font-medium text-justify pt-5 sm:pt-7'>Google Login Functionality</h3>
            <p>Our Google login functionality is designed to provide an additional layer of security for your account. When
                you log in using sign, we will send a one-time code to your registered  email address. You
                will need to enter this code in order to log in to your account.
                We only
                use this information to verify your identity and protect your account. If you have any questions or concerns
                about our privacy policy or Google sinin functionality, please contact us at beyondscool@gmail.com.​​</p>


        </div>
    )
}

export default Privacy