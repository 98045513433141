import React, { useState, useEffect } from 'react';
import { images } from '../../../Constants';

const curriculum = [
    {name:"COMPREHENSIVE CURRICULUM", content: "Our curated curriculum covers a diverse range of subjects and industries, designed to meet the needs of learners at every stage of their academic and professional careers." },
    { name: "Interactive Learning", content: "Engaging and interactive learning methods are employed to enhance understanding and retention of concepts." },
    { name: "Expert Instructions", content: "Expertly crafted instructions span various subjects and industries, catering to learners at all academic and professional levels." },
    { name: "Community Support", content: "Our curriculum fosters a sense of community by addressing diverse subject matters and industry needs." },
    { name: "Flexible learning", content: "Flexibility is key in our curriculum design, accommodating learners' needs at every stage of their academic and professional journey." }
];

export const Curriculum = () => {
    const [selectedContent, setSelectedContent] = useState(curriculum[0].content);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const nextIndex = (activeIndex + 1) % curriculum.length;
            setSelectedContent(curriculum[nextIndex].content);
            setActiveIndex(nextIndex);
        }, 5000);
        return () => clearInterval(interval);
    }, [activeIndex]);

    const handleLiClick = (content, index) => {
        setSelectedContent(content);
        setActiveIndex(index);
    };

    return (
        <div>
            <div
                className='group-33 px-5 pt-5 md:p-16 text-left font-inter'
                style={{ backgroundImage: `url(${images.blurbackground}) `, backgroundSize: 'cover', backgroundColor: 'rgb(89 96 255 / 50%)' }}
            >
                <h2 className='text-3xl md:text-7xl font-medium md:font-light italic'>What sets us apart?</h2>
                <div className='flex text-2xl md:text-4xl font-bold uppercase text-justify pt-8 pb-2 md:pb-10'>
                    {/* <p>Comprehensive <br className='md:hidden' /> Curriculum</p> */}
                </div>

                {/* <hr className='border-1px border-black w-60 mb-5 md:hidden' /> */}

                <div className='content md:flex md:flex-row-reverse justify-between items-center'>
                    <div className='hidden md:flex text-2xl sm:text-3xl md:text-4xl text-justify font-medium pb-4 md:w-[547px]'>{selectedContent}</div>
                    <ul>
                        {curriculum.map((data, index) => (
                            <React.Fragment key={index}>
                                <hr className='border-1px border-black w-60 md:w-96' />
                                <li className={`text-2xl md:text-3xl font-bold py-6 md:py-8 uppercase cursor-pointer duration-500 ${activeIndex === index ? 'active font-extrabold pl-6  md:pl-5 ' : ''}`} onClick={() => handleLiClick(data.content, index)}>{data.name}</li>
                                <div className={` md:hidden flex text-xl sm:text-3xl md:text-4xl text-justify font-medium pb-4 md:w-[547px] duration-500 ${activeIndex === index ? 'block' : 'hidden'} `}>{selectedContent}</div>
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
