import React from 'react';
import { images } from '../../Constants';
import { NavLink } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

const socialink = [

  {
    imgUrl: images.instagram,
    link: "https://www.instagram.com/beyondscool_?igsh=ZmhjMXJsbzUxdTdj"
  },
  {
    imgUrl: images.linkedin,
    link: "https://www.linkedin.com/company/beyondscool/?viewAsMember=true"
  },
  {
    imgUrl: images.youtube,
    link: "https://www.youtube.com/channel/UC2MASX0K6Tn3oikYcnMORMA"
  },
];

const courses = [
  {
    name: "CLASS IX",
    link: "/courses"
  },
  {
    name: "CLASS X",
    link: "/courses"
  },
  {
    name: "CLASS XI",
    link: "/courses"
  },
  {
    name: "CLASS XII",
    link: "/courses"
  }, {
    name: "SAT EXAM",
    link: "/courses"
  },
]

const quickLink = [
  {
    name: "HOME",
    link: "/"
  },
  {
    name: "ABOUT US",
    link: "/about"
  },
  {
    name: "COURSES",
    link: "/courses"
  },
  {
    name: "CONTACT US",
    link: "/contact"
  },
  {
    name: "PRIVACY POLICY",
    link: "/privacypolicy"
  },
  {
    name: "TERMS & CONDITIONS",
    link: "/terms&conditions"
  }, {
    name: "REFUND POLICY",
    link: "/refundpolicy"
  },
];

const contactUs = [
  {
    name: "+91 9972148403​",
    link: "tel:+91 9972148403​",
    imgUrl: images.telephone
  },
  {
    name: "+91 9319339686",
    link: "tel:+91 9319339686",
    imgUrl: images.telephone
  },
  {
    name: "team@beyondscool.com",
    link: "mailto:team@beyondscool.com",
    imgUrl: images.gmail
  },
  {
    name: "Bommanahalli, Kodichikknahalli, Banglore - 560076",
    link: "https://www.google.com/maps/place/Kodichikkanahalli+Main+Rd,+Seenappa+Layout,+Bommanahalli,+Bengaluru,+Karnataka/@12.8976497,77.6141846,20z/data=!4m6!3m5!1s0x3bae14ddddd53d79:0x507ae18c6090fa7c!8m2!3d12.8981034!4d77.618035!16s%2Fg%2F1tdvc053?entry=tts",
    imgUrl: images.location
  },
]




export const Footer = () => {
  return (
    <div>
      <footer className='bg-[#5960FF] text-white p-5 md:px-16 pt-16 pb-5'>
        <NavLink to="/" className="flex items-center gap-1 md:gap-3">
          <div className='logo w-12 h-12 md:w-20 md:h-20'>
            <img src={images.logo} alt='beyondscool' />
          </div>
          <div className='websitename flex items-center font-inter italic font-extrabold text-2xl md:text-5xl h-7'>
            Beyondscool
          </div>
        </NavLink>

        <div className='flex flex-col lg:flex-row md:justify-between'>
          <div className=' section-1 flex flex-col'>
            <div className='text-xl font-normal text-justify pt-4 md:w-[473px]'>Beyondscool is E-Learning Firm who personalise your Learning Expeirence through our Large range of courses and Live Batches.</div>
            {/* for laptop */}
            <div className='cotanct hidden md:flex flex-row items-center pt-8 gap-8'>
              <div className='w-20 h-20'>
                <img src={images.Vector} alt="" />
              </div>
              <div className='text-left flex flex-col gap-0'>
                <p className='text-[28px] font-bold'>Reach out to us</p>
                <p className='text-xl font-normal'>Get your questions answered</p>
                <p className='text-xl font-normal'>Call us at <a href='tel:+91 9876543210'>+91 9876543210</a></p>
              </div>
            </div>

            <div className='social link flex gap-6 pt-12'>
              {socialink.map((data, index) => (
                <a href={data?.link}
                  target="_blank"
                  className='w-9 h-9 md:w-12 md:h-12'
                >
                  <img src={data.imgUrl} alt="" />
                </a>
              ))}
            </div>
          </div>


          <div className='section-3 hidden md:block text-left pt-8 md:pt-4'>
            <h2 className='text-2xl font-semibold uppercase pb-6'>Contact Us</h2>
            <div className='flex flex-col gap-5 pb-8 md:pb-8 '>
              {contactUs.map((data, index) => (
                <div className='flex text-xl items-center gap-6 md:w-[318px]'>
                  <div className='w-8 h-8'>
                    <img src={data.imgUrl} alt="" />
                  </div>
                  <a href={data.link}>{data.name}</a>
                </div>
              ))}
            </div>

            <div class="flex justify-center">
              <div class="relative w-[316px] h-[184px] md:w-[383px] md:h-[224px] border-[1px] border-white rounded-[4px]">
                <iframe
                  title="Location"
                  class="absolute top-0 left-0 w-full h-full"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d778045.8982790374!2d77.47615923421415!3d12.8905977752074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c2551299%3A0x18f1ac7e3b3101a7!2s12.89730853567345%2C%2077.61438716959002!5e0!3m2!1sen!2sus!4v1621359849306!5m2!1sen!2sus"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>


          <div className=' section-2 flex flex-col text-left font-semibold pt-12 md:pt-4'>
            <div className='Courses'>
              <h2 className='text-2xl pb-6 uppercase '>Courses</h2>
              <div className='flex flex-col list-disc pl-2'>
                {courses.map((data, index) => (
                  <li className='text-xl uppercase'><a href={data?.link}>{data.name}</a></li>
                ))}
              </div>
            </div>

            <div className='Quick-link pt-8'>
              <h2 className='text-2xl pb-6 uppercase '>Quick Links</h2>
              <div className='flex flex-col list-disc pl-2'>
                {quickLink.map((data, index) => (
                  <li className='text-xl uppercase'><a href={data?.link}>{data.name}</a></li>
                ))}
              </div>
            </div>
          </div>


          <div className='section-3 md:hidden text-left pt-8 md:pt-4'>
            <h2 className='text-2xl font-semibold uppercase pb-6'>Contact Us</h2>
            <div className='flex flex-col gap-5 pb-8 md:pb-8 '>
              {contactUs.map((data, index) => (
                <div className='flex text-xl items-center gap-6 md:w-[318px]'>
                  <div className='w-8 h-8'>
                    <img src={data.imgUrl} alt="" />
                  </div>
                  <a href={data?.link}>{data.name}</a>
                </div>
              ))}
            </div>

            <div class="flex justify-center">
              <div class="relative w-[316px] h-[184px] md:w-[383px] md:h-[224px] border-[1px] border-white rounded-[4px]">
                <iframe
                  title="Location"
                  class="absolute top-0 left-0 w-full h-full"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d778045.8982790374!2d77.47615923421415!3d12.8905977752074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c2551299%3A0x18f1ac7e3b3101a7!2s12.89730853567345%2C%2077.61438716959002!5e0!3m2!1sen!2sus!4v1621359849306!5m2!1sen!2sus"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>

        </div>

        <div className='flex justify-center'>
          <hr className='border-2 border-white w-[300px] md:w-[700px] mt-8 md:mt-12 mb-6 md:mb-4'></hr>
        </div>
        <div className='text-xs font-normal'>Kapinova  © 2024. All Rights Reserved.
          <NavLink to="/privacypolicy">
            Privacy Policy
          </NavLink> </div>
      </footer>
    </div>
  )
}
