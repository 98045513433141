import logo from "../assets/logo/logo.png";
import HeroSectionRight from "../assets/stocks/HeroSectionRight.png";
import HeroSectionRightSm from "../assets/stocks/HeroSectionRightSm.png";
import CharacterObject from "../assets/stocks/CharacterObject.png";
import user from "../assets/stocks/user.png";
import home from "../assets/stocks/home.png";

import blurbackground from "../assets/stocks/blurbackground.png";
import live from"../assets/stocks/live.png";
import faq from "../assets/stocks/faq.png";
import faq1 from "../assets/stocks/q11.png";
import faq2 from "../assets/stocks/q12.png";
import faq3 from "../assets/stocks/q13.png";
import back from "../assets/stocks/backb.png";

import Vector from "../assets/stocks/Vector.png";
import instagram from "../assets/stocks/instgram.png";
import whatsapp from "../assets/stocks/whatsapp.png";
import linkedin from "../assets/stocks/linkedin.png";
import facebook from "../assets/stocks/facebook.png";
import browser from "../assets/stocks/browser.png";
import youtube from "../assets/stocks/youtube.png";

import telephone from "../assets/stocks/telephone.png";
import gmail from "../assets/stocks/gmail.png";
import location from "../assets/stocks/location.png";

import contactimg from "../assets/stocks/contactimg.png";
import getInTouch from "../assets/stocks/getInTouch.png";
import getCall from "../assets/stocks/getCall.png";
import DropDown from "../assets/stocks/Dropdown.png";


import class10 from "../assets/courseimg/class10.jpg";
import class11 from "../assets/courseimg/class11.jpg";
import classSatMath from "../assets/courseimg/classSatMath.jpg";
import classMath from "../assets/courseimg/classMath.jpg";
import ques from "../assets/stocks/q15.png";
import screen from "../assets/courseimg/screen.png";

import SAT_BOOST from "../assets/courseimg/SAT_BOOST.png";
import SAT_CORE from "../assets/courseimg/SAT_CORE.png";
import SAT_FOUNDATION from "../assets/courseimg/SAT_FOUNDATION.png";
export default{
    back,
    faq1,
    faq2,
    faq3,
    ques,
    logo,
    HeroSectionRight,
    HeroSectionRightSm,
    blurbackground,
    live,
    faq,
    Vector,
    instagram,
    facebook,
    whatsapp,
    linkedin,
    browser,
    telephone,
    gmail,
    location,
    contactimg,
    getInTouch,
    getCall,
    DropDown,
    CharacterObject,
    user,
    home,
    class10,
    class11,
    classMath,
    classSatMath,
    youtube,
    SAT_BOOST,
    SAT_CORE,
    SAT_FOUNDATION,
    screen
};
