import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import displayRazorpay from '../../../utils/PaymentGateway';
import { images } from '../../../Constants';


const batches = [
    {
        imgUrl: images.class11,
        name: "Start Batch",
        class: "IX",
        subject: "Mathematics, Science",
        classSize: "10-15 students",
        liveClass: "4 days a week",
        additional: "1 Test every week",
        amount: "3999"
    },
    {
        imgUrl: images.class10,
        name: "Win Batch",
        class: "X",
        subject: "Mathematics, Science",
        classSize: "5-10 students",
        liveClass: "4 days a week",
        additional: "1 Test every week",
        amount: "4999"
    },
    {
        imgUrl: images.class11,
        name: "Hindi",
        class: "IX",
        subject: "HINDI",
        classSize: "10-15 students",
        liveClass: "3 days a week",
        additional: "1 Test every week",
        amount: "2999"
    },
    {
        imgUrl: images.class11,
        name: "Hindi",
        class: "For All age gruop",
        subject: "HINDI",
        classSize: "5-10 students",
        liveClass: "3 days a week",
        additional: "1 Test every week",
        amount: "2999"
    },
    {
        imgUrl: images.class10,
        name: "Hindi",
        class: "X",
        subject: "HINDI",
        classSize: "10-15 students",
        liveClass: "3 days a week",
        additional: "1 Test every week",
        amount: "2999"
    }
]

export const Batches = () => {
    const [selectedBatches, setSelectedBatches] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [filteredBatches, setFilteredBatches] = useState([]);

    useEffect(() => {
        let filtered = batches;
        if (selectedBatches !== '') {
            filtered = filtered.filter(batch => batch.class === selectedBatches);
        }
        if (selectedSubject !== '') {
            filtered = filtered.filter(batch => batch.subject === selectedSubject);
        }
        setFilteredBatches(filtered);
    }, [selectedBatches, selectedSubject]);

    return (
        <div className=' pb-10'>
            <div className='flex justify-around md:justify-end md:gap-1 pt-2 '>
                <div className='flex flex-col justify-end items-center pt-2 rounded-md'>
                    <select className={`border-[0.5px] border-black rounded-[4px] p-2 m-4 cursor-pointer w-40`} value={selectedBatches} onChange={(e) => setSelectedBatches(e.target.value)}>
                        <option value="" disabled selected hidden>Select Class</option>
                        <option value="">All Classes</option>
                        {[...new Set(batches.map(data => data.class))].map((classValue, index) => (
                            <option key={index} value={classValue}>{classValue}</option>
                        ))}
                    </select>
                </div>
                <div className='flex flex-col justify-end items-center pt-2 rounded-md'>
                    <select className='border-[0.5px] border-black rounded-[4px] p-2 m-4 cursor-pointer w-40' value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)}>
                        <option value="" disabled selected hidden>Select Subject</option>
                        <option value="">All Subjects</option>
                        {[...new Set(batches.map(data => data.subject))].map((subjectValue, index) => (
                            <option key={index} value={subjectValue}>{subjectValue}</option>
                        ))}
                    </select>
                </div>
            </div>


            {/* course components */}

            <div className='course-cards px-8 pt-8 pb-20 flex flex-col md:flex-row md:justify-center flex-wrap gap-16  items-center  '>
                {filteredBatches ? filteredBatches?.map((data, index) => (
                    <div className='border-[1px] border-black rounded-3xl w-[319px] relative transition duration-300 transform hover:shadow-lg hover:scale-110 hover:pointer hover:bg-gray-150' key={index}>
                        <div className="border-b-[1px]  border-black h-[171px] bg-gray-300 rounded-t-3xl">
                            <img className='rounded-t-3xl' src={data.imgUrl ? data.imgUrl : ''} alt="" />
                        </div>
                        <div className='p-3 text-left min-h-[270px]'>
                            <div className='flex justify-between'>
                                <span className='text-xl font-bold'>{data.name}</span>
                            </div>
                            <ul className='py-3 pl-6 list-disc'>
                                <li className='font-normal text-xs py-1'><span className='font-bold'>Class :</span> {data.class}</li>
                                <li className='font-normal text-xs py-1'><span className='font-bold'>Subject :</span> {data.subject}</li>
                                <li className='font-normal text-xs py-1'><span className='font-bold'>Class size :</span> {data.classSize}</li>
                                <li className='font-normal text-xs py-1'><span className='font-bold'>Live Classes :</span> {data.liveClass}</li>
                                <li className='font-normal text-xs py-1'>{data.additional}</li>
                            </ul>
                            <div>
                                <strong className='text-xl font-bold'>Rs. {data.amount}</strong><span className='text-xs font-light'>/month</span>
                            </div>
                        </div>
                        <div onClick={() => displayRazorpay(data.amount, data.class, data.subject)} className='absolute bottom-0 left-0 right-0 border-t-[1px] rounded-b-3xl border-black bg-[#5960FF] text-center text-2xl font-bold text-white py-2 cursor-pointer'>
                            Enroll Now
                        </div>
                    </div>

                )) : "Soon courses is Available"}
            </div>

        </div>
    )
}
