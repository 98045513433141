import React from 'react'

const Terms = () => {
  return (
    <div>
    <div
                className='group-33 p-4  sm:p-16  text-left font-inter'
                // style={{ backgroundImage: `url(${images.blurbackground}) `, backgroundSize: 'cover', backgroundColor: 'rgb(89 96 255 / 40%)' }}
            >
                <h2 className=' text-3xl sm:text-4xl font-extrabold'> TERMSANDCONDITIONS</h2>
                <p className='text-xs sm:text-xl font-medium text-justify pt-5 sm:pt-7'>At Beyondscool , we believe in the transformative power of education. In today's rapidly evolving world, learning is not confined to traditional classrooms; it spans across digital platforms, connecting individuals globally and empowering them to unlock their full potential. Established with a vision to revolutionize education through technology, BeyondScool is a leading provider of innovative online learning solutions. Whether you're a student seeking to expand your knowledge, a professional aiming to upskill, or an organization looking to train your workforce, we have the tools, resources, and expertise to support your learning journey.​​</p>

            </div>
            </div>
  )
}

export default Terms