import React from 'react';
import { images } from '../../../Constants';
import { NavLink } from 'react-router-dom';

const marquee = ["CBSE", "ICSE", "IGCSE", "IB BOARD", "SAT"]

export const HeroSection = () => {
    return (
        <div >

            {/* hero section 1 */}
            <div className='hidden md:block absolute right-0 h-[821px] z-[-1]'>
                <img src={images.HeroSectionRight} alt="hr" />
            </div>

            <div className=' '>
                <div className=' px-4 md:p-16 text-left md:h-[821px] '>
                    <div className='flex justify-center py-20 md:hidden  '>
                        <img src={images.HeroSectionRightSm} alt="" />
                    </div>

                    <div className='text-xl md:text-6xl font-black pb-3 md:pb-8 uppercase italic md:pt-40'>Don't let your child's<br></br> potential go untapped.</div>

                    <div className='text-xs md:text-xl text-justify font-normal pb-5 md:pb-8 md:w-[713px]'>Unlock limitless possibilities for their future today! Empower their journey with us today. Click below to schedule a call and take the first step towards their success.</div>

                    <div className="bg-[#FFCC00] duration-500 hover:scale-110 w-56 md:w-80 text-black rounded-lg md:rounded-3xl my-2 border-4 border-[#FFCC00] shadow-md shadow-[#FFCC00] flex items-center justify-center">
                        <NavLink
                            className={`flex gap-3 py-1 px-2 items-center`}
                            to="/contact"
                            activeClassName="text-blue"
                        >
                            <div className=' w-4 h-4 md:w-8 md:h-8'>
                                <img src={images.getCall} alt="getcall" />
                            </div>

                            <span className={` text-[16px] md:text-2xl font-bold whitespace-nowrap`}>
                                Schedule a Call
                            </span>
                        </NavLink>
                    </div>

                    <div className="overflow-hidden mt-20 relative z-[-1]">
                        <marquee className="text-xl md:text-7xl font-bold py-4 md:py-6" behavior="scroll" direction="left" scrollamount="10">
                            {marquee.map((item, index) => (
                                <span key={index}>
                                    {index > 0 && <span className=" mx-4 md:mx-12">&#8226;</span>}
                                    {item}
                                </span>
                            ))}
                        </marquee>
                    </div>

                </div>
            </div>


            {/* hero section 2 */}
            <div
                className='group-33 p-4  sm:p-16  text-left font-inter'
                style={{ backgroundImage: `url(${images.blurbackground}) `, backgroundSize: 'cover'}}
            >
                <h2 className=' text-2xl sm:text-4xl font-extrabold'>Welcome to Beyond Scool</h2>
                <p className='text-xs sm:text-xl font-medium text-justify pt-5 sm:pt-10'>At Beyondscool , we believe in the transformative power of education. In today's rapidly evolving world, learning is not confined to traditional classrooms; it spans across digital platforms, connecting individuals globally and empowering them to unlock their full potential. Established with a vision to revolutionize education through technology, BeyondScool is a leading provider of innovative online learning solutions. Whether you're a student seeking to expand your knowledge, a professional aiming to upskill, or an organization looking to train your workforce, we have the tools, resources, and expertise to support your learning journey.​​</p>

            </div>
        </div>
    )
}
