import React, { useState,useRef,useEffect } from 'react';
import emailjs from '@emailjs/browser'
import { images } from '../../Constants';

export const Contact = () => {

    const [formData, setFormData] = useState({
        name: '',
        mobileNumber: '',
        email: '',
        class: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const form = useRef();
    useEffect(()=>{
        setTimeout(() => {
          //  setLetterClass('text-animate-hover')
       }, 3000)
   },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can perform form submission logic here
        console.log(formData);
        emailjs
        .sendForm("service_08e9ymb","template_d0s532w",form.current, "Gt_dCiq7BguQ5Wy3D")
        .then(
          () => {
            alert('Message successfully sent!')
            window.location.reload(false)
          },
          () => {
            alert('Failed to send the message, please try again')
          }
        )


        setFormData({
            name: '',
            mobileNumber: '',
            email: '',
            class: ''
        });
    };

    return (
        <div className=' bg-[#F4F4F4] py-4 pb-24 px-4 md:p-8 md:px-16 relative'>

            <h2 className='text-[28px] md:text-[64px] font-bold text-left'>Join Us on Your Learning Journey​​</h2>
            <p className='text-xs md:text-2xl font-normal text-justify'>Whether you're embarking on a new educational adventure or seeking to expand your skill set, Beyondscool is here to guide you every step of the way. Join us today and unlock a world of knowledge, growth, and opportunity.​</p>


            <div className='flex justify-center md:mb-16'>
                <div className='contact-from bg-white mt-8 md:my-14 md:py-16 w-[282px] w-full h-[500px] md:flex md:justify-evenly md:items-center md:w-[1024px]  rounded-[32px] border-[12px] border-[#FECB01]'>
                    <form ref={form} onSubmit={handleSubmit} className='text-xl md:text-2xl text-left font-normal text-[#00000080] py-[38px] px-3'>
                        <div className="flex flex-col">
                            <label htmlFor="name" >Name</label>
                            <input
                                className='border-[1px] border-black rounded-lg w-[235px] h-[45px] md:w-[364px] mb-2 md:mb-5 px-1'
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="mobileNumber">Mobile Number</label>
                            <input
                                className='border-[1px] border-black rounded-lg w-[235px] h-[45px] md:w-[364px] mb-2 md:mb-5 px-1'
                                type="tel"
                                id="mobileNumber"
                                name="mobileNumber"
                                value={formData.mobileNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="email">Email ID</label>
                            <input
                                className='border-[1px] border-black rounded-lg w-[235px] h-[45px] md:w-[364px] mb-2 md:mb-5 px-1'
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="class">Class</label>
                            <input
                                className='border-[1px] border-black rounded-lg w-[235px] h-[45px] md:w-[364px] mb-2 md:mb-5 px-1 '
                                type="text"
                                id="class"
                                name="class"
                                value={formData.class}
                                onChange={handleChange}
                            />
                        </div>
                        {/* this button work for mobile view */}
                        <button
                            className=' md:hidden bg-[#FFCC00] text-black text-xl rounded-[20px] px-5 py-2 mt-14 md:mt-2 float-right '
                            type="submit">Submit
                        </button>

                        {/* this button work for laptop view */}
                        <div className=' hidden md:flex justify-center'>
                            <button
                                className='bg-[#FFCC00] text-black text-xl rounded-[20px] px-5 py-2 mt-14 md:mt-2 '
                                type="submit">Submit
                            </button>
                        </div>

                    </form>
                    <div className=' hidden md:block w-[400px] h-[400px]'>
                        <img src={images.contactimg} alt="contactimg" />
                    </div>
                </div>
            </div>
            <div className=' w-60 h-60 hidden xl:w-[400px] xl:h-[400px]  absolute bottom-2 left-0  '>
                <img src={images.getInTouch} alt="getintouch" />
            </div>

        </div>
    )
}