import React from 'react';
import { Link } from 'react-router-dom';

const UnauthorizedAccess = () => {
    return (
        <div
            
            className="flex flex-col items-center justify-center h-screen">

            <h1
                
                className="text-4xl sm:text-8xl font-bold mb-6 "
            >
                404
            </h1>

            <h1
                
                className="text-4xl font-bold mb-6"
            >
                Page Not Found!
            </h1>

            <p
                
                className="text-lg text-gray-600 mb-8 text-center"
            >
                Please login to access this website.
            </p>
            <div
                
            >
                <Link
                    to="/register"
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Login
                </Link>
            </div>
        </div>
    );
};

export default UnauthorizedAccess;
