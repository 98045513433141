import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.css';
import { images } from '../../Constants';
import { useAuth } from '../../AuthProvider/AuthProvider';

import { SlideIn } from './SlideIn/SlideIn';


export const Navbar = () => {

    const {isLoggedIn} = useAuth();
    const {setIsLoggedIn} = useAuth()

    const userImg = localStorage.getItem("userImg");

    const [isMobile, setIsMobile] = useState(false);
    const [active, setActive] = useState(0);
    const [toggle, setToggle] = useState(false);
    

    const Menus = [
        {name:"Home",path:"/"},
        { name: "About", path: "/about" },
        { name: "Courses", path: "/courses" },

    ]


    const location = useLocation();
    const path = location.pathname;

    const handleClick = (index) => {
        setActive(index);
    }

    const handleToggle = () => {
        setToggle(!toggle);
    }

    useEffect(()=>{
        const email = localStorage.getItem("email");
        if(email)
        {
            setIsLoggedIn(true);
        }
    })

    useEffect(() => {
        if (toggle) {
            document.body.style.overflow = 'hidden'; // Disable scroll
        } else {
            document.body.style.overflow = 'auto'; // Enable scroll
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [toggle]);


    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900);
        };
        // Initial check
        handleResize();
        // Event listener for window resize
        window.addEventListener("resize", handleResize);

        // Cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

//     return (
//         <navbar className={` bg-[#000AFFA6] py-3 px-3 flex items-center justify-between text-white h-[72px] md:h-[90px] ${toggle || path === "/user" && "fixed w-full bg-[#5960FF]"} `}>
//             <NavLink to="/" className="flex items-center gap-1 md:gap-3">
//                 <div className='logo w-12 h-12 md:w-20 md:h-20'>
//                     <img src={images.logo} alt='beyondscool' />
//                 </div>
//                 <div className='websitename flex items-center font-inter italic font-extrabold text-2xl  xl:text-5xl h-7'>
//                     Beyondscool
//                 </div>
//             </NavLink>
//             {isMobile ?
//                 <div>
//                     <div className=" text-3xl" onClick={() => handleToggle()}>
//                         {!toggle ? <i class="fa-solid fa-bars"></i> : <i class="fa-solid fa-xmark"></i>}
//                     </div>
//                     {toggle &&
//                         <div className='fixed top-[72px] left-0 w-screen h-screen  bg-opacity-50 flex justify-center items-center z-50 text-black'>
//                             <SlideIn toggle={toggle} setToggle={setToggle} />
//                         </div>}
//                 </div> :
//                 <>
//                     <div className='navoption flex'>
//                         <ul className='flex gap-8'>
//                             {Menus?.map((menu, i) => (
//                                 <li className={`links`} key={i}>
//                                     <NavLink
//                                         className={`flex gap-3 py-2 px-2 ${i === active && " underline"}`}
//                                         to={menu.path}
//                                         activeClassName="text-blue"
//                                         onClick={() => handleClick(i)}
//                                     >

//                                         <span className={` text-2xl font-semibold  `}>
//                                             {menu.name}
//                                         </span>
//                                     </NavLink>
//                                 </li>
//                             ))}
//                         </ul>

//                         <div className='flex justify-center md:pl-8'>
//                             {!isLoggedIn ? (
//                                 <div className="bg-[#FFCC00] text-black rounded-3xl px-4 border-4 border-white shadow-lg flex items-center">
//                                     <NavLink
//                                         className={`flex gap-3 py-2 px-2 `}
//                                         to="/register"
//                                     >
//                                         <span className={` text-2xl font-semibold whitespace-nowrap `}>
//                                             Login/Sign up
//                                         </span>
//                                     </NavLink>
//                                 </div>
//                             ) : (
//                                 <div className='w-[50px] h-[50px]' >
//                                     <NavLink to="/myprofile">
//                                         <img className='rounded-full' src={userImg} alt="user"/>
//                                     </NavLink>
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </>}
//         </navbar>
//     )
// }

    return(
    <navbar className={` bg-[#000AFFA6] py-3 px-3 flex items-center justify-between text-white h-[72px] md:h-[90px] ${toggle || path === "/user" && "fixed w-full bg-[#5960FF]"} `}>
        <NavLink to="/" className="flex items-center gap-1 md:gap-3" activeClassName="underline">
            <div className='logo w-12 h-12 md:w-20 md:h-20'>
                <img src={images.logo} alt='beyondscool' />
            </div>
            <div className='websitename flex items-center font-inter italic font-extrabold text-2xl lg:text-5xl h-7'>
                Beyondscool
            </div>
        </NavLink>
        {isMobile ?
            <div>
                <div className=" text-3xl" onClick={() => handleToggle()}>
                    {!toggle ? <i className="fa-solid fa-bars"></i> : <i className="fa-solid fa-xmark"></i>}
                </div>
                {toggle &&
                    <div className='fixed top-[72px] left-0 w-full h-screen  bg-opacity-50 flex justify-center items-center z-50 text-black'>
                        <SlideIn toggle={toggle} setToggle={setToggle} />
                    </div>}
            </div> :
            <>
                <div className='navoption flex'>
                    <ul className='flex gap-8'>
                        {Menus?.map((menu, i) => (
                            <li className={`links`} key={i}>
                                <NavLink
                                    className={`flex gap-3 py-2 px-2 ${path === menu.path ? "" : ""}`}
                                    to={menu.path}
                                    activeClassName="text-blue"
                                    onClick={() => handleClick(i)}
                                >
                                    <span className={`text-2xl font-semibold`}>
                                        {menu.name}
                                    </span>
                                </NavLink>
                            </li>
                        ))}
                    </ul>

                    <div className='flex justify-center md:pl-8'>
                        {!isLoggedIn ? (
                            <div className="bg-[#FFCC00] text-black rounded-3xl px-4 border-4 border-white shadow-lg flex items-center">
                                <NavLink
                                    className={`flex gap-3 py-2 px-2 `}
                                    to="/register"
                                >
                                    <span className={` text-2xl font-semibold bg-yellow-300 `}>
                                        Login/Sign up
                                    </span>
                                </NavLink>
                            </div>
                        ) : (
                            <div>
                                <NavLink to="/myprofile" className='w-[50px] h-[50px]'>
                                    <img className='rounded-full h-[52px]' src={userImg} alt="user"/>
                                </NavLink>
                            </div>
                        )}
                    </div>
                </div>
            </>}
    </navbar>
)}
