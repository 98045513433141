import React, { useEffect } from 'react';
import { NavLink, Routes, Route, useLocation } from 'react-router-dom';
import { images } from '../../Constants';
import { Batches } from './Batches/Batches';
import { Courses } from './Courses/Courses';


export const Learning = () => {

    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        console.log("path", path);
    })

    return (
        <div className=' '>
            
            <div className='bg-[#e5e5e5] py-2 flex flex-row justify-evenly'>
                <NavLink
                    to="./"
                    className={`text-2xl font-light duration-200 contents sm:text-4xl ${(path === "/courses/" || path === "/courses") && "font-medium border-b-[0.5px] border-black "}`}
                >Courses</NavLink>
                <hr className=' border-[0.5px] w-0 h-[35px] border-[#00000080]'></hr>
                <NavLink
                    to="./batches"
                    className={`text-2xl font-light duration-200 contents sm:text-4xl ${path === "/courses/batches" && "font-medium border-b-[0.5px] border-black "}`}
                >Batches</NavLink>

            </div>

            <Routes>
                <Route path="/" element={<Courses />} />
                <Route path="batches" element={<Batches />} />
            </Routes>

            {/* bottom */}
{/*             <div className=' bg-[#5960FF] w-full px-10 py-5  text-left text-white rounded-t-lg md:hidden'>
                <h2 className='text-3xl font-bold'>Still Confused?</h2>
                <p className='text-[16px] font-normal'>Our expert can answer all your questions.</p>

                <div className="bg-[#FFCC00] text-black rounded-3xl px-4 my-2 border-4 shadow-lg flex items-center justify-center">
                    <NavLink
                        className={`flex gap-3 py-2 px-2 `}
                        to="/register"
                        activeClassName="text-blue"
                    >
                        <div className=' w-8 h-8'>
                            <img src={images.getCall} alt="getcall" />
                        </div>

                        <span className={` text-2xl font-semibold  whitespace-nowrap`}>
                            Get a Call from us
                        </span>
                    </NavLink>
                </div>

                <p className='text-[16px] font-normal'> or Call us at  <a href="tel:+91 9876543210" >+91 9876543210 </a></p>
            </div> */}
        </div>
    )
}
