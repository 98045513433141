import React, { useEffect, useState } from 'react';
import displayRazorpay from '../../../utils/PaymentGateway';
import { useAuth } from '../../../AuthProvider/AuthProvider';
import { images } from '../../../Constants';

const courses = [
    {
        imgUrl: images.SAT_BOOST,
        class: "SAT",
        name: "SAT Math",
        subject: "Mathematics",
        classSize: "10-15 students",
        priceinUSD: "$50",
        additional: "5 LIVE DOUBT CLASS",
        amount: "1"
    },
    {
        imgUrl: images.SAT_CORE,
        class: "3,4,5,6,7",
        name: "Fraction,Decimal,Area part-1",
        subject: "Mathematics",
        classSize: "10-15 students",
        priceinUSD: "$85",
        additional: "notes,5 live classes, video for lifetime",
        amount: "6500"
    },
    {
        imgUrl: images.SAT_FOUNDATION,
        name: "Fraction,Decimal,Area part-2",
        class: "5,6,7,8",
        subject: "Mathematics",
        classSize: "10-15 students",
        priceinUSD: "$85",
        additional: "notes,5 live classes, video for lifetime",
        amount: "$200"
    },
    {
        imgUrl: images.classMath,
        class: "3,4,5,6,7",
        name: "ACT FOUNDATION",
        subject: "Mathematics",
        classSize: "10-15 students",
        priceinUSD: "$85",
        additional: "notes,5 live classes, video for lifetime",
        amount: "6500"
    },

]

export const Courses = () => {
    const { isloggedIn } = useAuth();

    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [filteredCourses, setFilteredCourses] = useState([]);

    useEffect(() => {
        let filtered = courses;
        if (selectedClass !== '') {
            filtered = filtered.filter(course => course.class === selectedClass);
        }
        if (selectedSubject !== '') {
            filtered = filtered.filter(course => course.subject === selectedSubject);
        }
        setFilteredCourses(filtered);
    }, [selectedClass, selectedSubject]);

    return (
        <div className='pb-10'>
            <div className='flex justify-around md:justify-end md:gap-1 pt-2 '>
                <div className='flex flex-col justify-end items-center pt-2  rounded-md'>
                    <select className='border-[0.5px] border-black rounded-[4px] p-2 m-4 w-40 cursor-pointer' value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
                        <option value="" disabled selected hidden>Select Class</option>
                        <option value="">All</option>
                        {[...new Set(courses.map(data => data.class))].map((classValue, index) => (
                            <option key={index} value={classValue}>{classValue}</option>
                        ))}
                    </select>
                </div>
                <div className='flex flex-col justify-end items-center pt-2 rounded-md'>
                    <select className='border-[0.5px] border-black rounded-[4px] p-2 m-4 cursor-pointer w-40' value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)}>
                        <option value="" disabled selected hidden>Select Subject</option>
                        <option value="">All Subjects</option>
                        {[...new Set(courses.map(data => data.subject))].map((subjectValue, index) => (
                            <option key={index} value={subjectValue}>{subjectValue}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='course-cards px-8 pt-8 pb-20 flex flex-wrap flex-col md:flex-row md:justify-center gap-16  items-center '>
                {filteredCourses.map((data, index) => (
                    <div className='border-[1px] border-black rounded-3xl w-[319px] relative transition duration-300 transform hover:shadow-lg hover:scale-110 hover:pointer hover:bg-gray-150' key={index}>
                        <div className=" border-b-[1px]  border-black h-[171px] bg-gray-300 rounded-t-3xl">
                            <img className='rounded-t-3xl' src={data.imgUrl ? data.imgUrl : ''} alt="" />
                        </div>
                        <div className='p-3 text-left'>
                            <div className='flex justify-between'>
                                <span className=' text-xl font-bold'>{data.name}</span>
                                {/* <span className=' text-xl font-extralight'>{data.class}</span> */}
                            </div>
                            <ul className=' py-3 pl-6 list-disc '>
                                <li className=' font-normal text-xs py-1'><span className=' font-bold'>Class :</span> {data.class}</li>
                                <li className=' font-normal text-xs py-1'><span className=' font-bold'>Subject :</span> {data.subject}</li>
                                <li className=' font-normal text-xs py-1'><span className=' font-bold'>Class size :</span> {data.classSize}</li>
                                <li className=' font-normal text-xs py-1'><span className=' font-bold'>priceinUSD :</span> {data.priceinUSD}</li>
                                <li className=' font-normal text-xs py-1'>{data.additional}</li>
                            </ul>
                            <div><strong className='text-xl font-bold'>Rs. {data.amount}</strong><span className='text-xs font-light'></span></div>
                        </div>
                        <div onClick={() => displayRazorpay(data.amount, data.class, data.subject, isloggedIn)} className='border-t-[1px] rounded-b-3xl border-black bg-[#5960FF] text-center text-2xl font-bold text-white py-2 cursor-pointer '>
                            Enroll Now
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
