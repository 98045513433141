import React from 'react'
import { HeroSection } from './HeroSection/HeroSection';
import { Batches } from '../Learning/Batches/Batches';
import { Curriculum } from './Curriculum/Curriculum';
import { Testimonial } from './Testimonial/Testimonial';
import { Offering } from './Offering/Offering';
import { FAQ } from './FAQ/FAQ';
import { Courses } from '../Learning/Courses/Courses';
import { Contact } from '../Contact/Contact';
import { Footer } from '../Footer/Footer';
import { NavLink } from 'react-router-dom';


export const Home = () => {
    return (
        <div className='App'>
            <HeroSection />

            {/* batches */}

            <div className='py-14 '>
                <p className='text-left uppercase text-2xl font-extrabold pl-4 contents sm:text-4xl'>Batches</p>
                <Batches />
                <div className=' duration-500 hover:scale-110'>
                    <NavLink to="courses/batches" className='rounded-full bg-[#5960FF] text-2xl px-11 py-3 text-white font-bold shadow-md  shadow-[#5960FF] cursor-pointer  '>See More<i className="fa-solid fa-arrow-right pl-4 flex items-center"></i></NavLink>
                </div>
            </div>

            {/* courses */}
            <div className='py-14 '>
                <p className='text-left uppercase text-2xl font-extrabold pl-4 contents sm:text-4xl'>Courses</p>
                <Courses />
                <div className=' duration-500 hover:scale-110'>

{/*                     <NavLink to="courses" className=' rounded-full bg-[#5960FF] text-2xl px-11 py-3 text-white font-bold shadow-md  shadow-[#5960FF] cursor-pointer ' >
                        See More<i className="fa-solid fa-arrow-right pl-4 flex items-center"></i>
                    </NavLink> */}
                     <a href="/courses" className=' rounded-full bg-[#5960FF] text-2xl px-11 py-3 text-white font-bold shadow-md  shadow-[#5960FF] cursor-pointer ' >
                        See More<i className="fa-solid fa-arrow-right pl-4 flex items-center"></i>
                    </a>
                    
                </div>
            </div>

            <Curriculum />
            <Offering />
            <FAQ />
            <Testimonial />
            <Contact />
            {/* <Footer /> */}


        </div>
    )
}
