import React from 'react';
import { images } from '../../../Constants';

const offering = [
    {
        imgUrl: images.faq1,
        offer: "Courses"
    },
    {
        imgUrl: images.live,
        offer: "LIVE CLASSES"
    },
    {
        imgUrl: images.faq2,
        offer: "LIVE DOUBT SUPPORT"
    },
    {
        imgUrl: images.faq3,
        offer: "SPECILIZED PROGRAMS"
    },
]

export const Offering = () => {
    return (
        <div className='py-14 px-4 md:p-16'>
            <h2 className='text-3xl font-bold text-center'>Our Offering</h2>

            <div className=' shadow-md shadow-gray-500 border-2 mt-8 py-8 xl:px-4 rounded-[10px] grid grid-cols-2 gap-12 md:flex md:justify-evenly md:flex-wrap lg:flex-nowrap'>
                {offering.map((data, index) => (
                    <div className=' md:flex justify-evenly'>
                        <div className='flex flex-col items-center justify-center'>
                            <div className=' w-[60px] h-[60px] '>
                                <img src={data.imgUrl} alt="live" />
                            </div>
                            <div className='text-xl font-bold uppercase'>{data.offer}</div>

                        </div>
                        {index !== offering.length - 1 && (
                            <hr className=' hidden md:block border-[0.5px] w-0 h-[156px] border-black md:ml-20 '></hr>)}
                    </div>
                ))}
            </div>
        </div>
    )
}
